import { css } from '@emotion/react';

export const title = css`
    color: #ffb300;
    font-weight: 900;
    font-size: 2.4rem;
    margin-bottom: 4rem;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -0.15em;
        left: 0.4em;
        width: 1.2em;
        height: 0.3em;
        background-image: radial-gradient(circle, #ffb300 35%, transparent 0);
        background-size: 0.3em 0.3em;
        background-repeat: repeat-x;
        background-position: 0 0;
    }
`;

export const status = css`
    padding: 0.5rem;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 600;
    color: #388e3c;
    transform: rotate(-10deg);

    text-align: right;
    margin-top: -2rem;
    padding-right: 2rem;
    margin-bottom: 0.5rem;

    @media (min-width: 27.5rem) {
        margin-top: 0;
        text-align: center;
        top: -1.5rem;
        margin-left: 14.5rem;
        position: absolute;
    }

    div:last-of-type {
        display: inline-block;
        font-weight: 900;
        font-size: 2rem;
        text-transform: uppercase;
        margin-top: 0.2rem;
        border-radius: 0.3rem;
        border: 0.3rem solid #388e3c;
        padding: 0.3rem 0.7rem;
    }

    &.closed {
        color: #ad2c2c;

        div:last-of-type {
            border-color: #ad2c2c;
        }
    }
`;

export const locs = css`
    & > div {
        max-width: 27rem;
        margin: 1rem auto 4rem;
    }

    @media (min-width: 50rem) {
        display: flex;
        justify-content: space-between;

        & > div {
            margin: 1rem 0 4rem;
            flex: 0 0 49%;
        }
    }
`;
