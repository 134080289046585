import React from 'react';

import { useLocations } from '@hooks';
import { Wrapper, SEO, Location, NoSSR } from '@components';

import * as s from './_styles';

const HoursPage = () => {
    const locations = useLocations();
    const isOpen = locations.some((location) => location.isOpen);

    return (
        <Wrapper>
            <SEO title="Store Hours" keywords={[`gatsby`, `application`, `react`]} />
            <h1 css={s.title}>Locations</h1>
            <NoSSR>
                <div css={s.status} className={isOpen ? '' : 'closed'}>
                    <div>We&apos;re currently</div>
                    <div>{isOpen ? 'Open' : 'Closed'}</div>
                </div>
                <div css={s.locs}>
                    {locations.map((location) => (
                        <div key={location.city}>
                            <Location location={location} />
                        </div>
                    ))}
                </div>
            </NoSSR>
        </Wrapper>
    );
};

export default HoursPage;
